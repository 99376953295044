<template>
    <div class="shops-modal">
        <modal
        :name="boxName"
        :width="boxWidth"
        :height="boxHeight"
        :adaptive="true" 
        >
            <div class="text__area" v-if="lot">
                <h4 class="text-center" > {{translateObject(lot, 'titleRu', 'titleKz')}} </h4>

                <p class="close" @click="hide"><i class="fas fa-times"></i></p>

                <div class="shops-modal__content">
                    <div class="row">
                        <div class="col-sm-5 col-12">№</div>
                        <div class="col-sm-7 col-12"> {{lot.number}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.source") }} </div>
                        <div class="col-sm-7 col-12"> 
                            <a target="blank" :href="getLink"> {{ translateObject(lot.system, 'nameRu', 'nameKz') }} </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12">{{ $t("lot.app_begin") }}</div>
                        <div class="col-sm-7 col-12"> {{lot.startDate}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.truId") }} </div>
                        <div class="col-sm-7 col-12"> {{lot.truId}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.zakaz") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(lot.customerBin, 'nameru', 'namekz') }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.organizerr") }} </div>
                        <div class="col-sm-7 col-12">{{lot.customBin}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.description") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(lot, 'descriptionRu', 'descriptionKz') }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.amount") }} </div>
                        <div class="col-sm-7 col-12"> {{lot.amount | sum}} ₸ </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("lot.hmethodtrade") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(lot.methodTrade, 'nameRu', 'nameKz') }} </div>
                    </div>
                </div>

                <button class="btn btn-close" @click="hide"> {{ $t("general.close") }} </button>              
            </div>
        </modal>
    </div>
</template>

<script>
import translateMix from '@/mixins/translate'
export default {
    name: 'lot-modal-box',
    mixins: [
        translateMix
    ],
    props: {
        boxName: {
            type: String,
            required: true
        },
        boxWidth: {
            type: Number,
            default: 1200
        },
        boxHeight: {
            default: 'auto'
        },
        lot: {
            type: Object
        }
    },
    data: () => ({
    }),
    methods: {
        show() {
            this.$modal.show(this.boxName);
        },
        hide() {
            this.$modal.hide(this.boxName)
        }
    },
    computed: {
        getLink() {
            let id = null;
            if (this.lot.system.id == 1) {
                id = this.lot.externalTenderId;
            } else {
                id = this.lot.externalId;
            }
            const url = this.lot.system.lotUrl;
            const newUrl = url && url.replace("@@@", id);
            return newUrl;
        }
    },
}
</script>   

<style lang="scss" scoped>
.shops-modal {
    position: relative;
    z-index: 9999;

    &__content{
        margin: 20px 0;
        // border: 1px solid #ccc;
        border: 1px solid rgb(231, 231, 231);
        border-radius: 5px;
        .row {
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid rgb(231, 231, 231);
            &:first-child{
                a {
                    display: inline;
                    color: #553DB4;
                }
            }
            &:last-child{
                border-bottom: none;
            }
            .col-12 {
                padding: 12px 15px;
                @media(max-width: 575px) {
                    text-align: center;
                }
            }
            .col-sm-5 {
                border-right: 1px solid #ccc;
                text-align: right;
                @media(max-width: 575px) {
                    text-align: center;
                    border-right: none;
                }
            }
            &:nth-child(odd) {
                background: rgb(241, 241, 241);
            }
        }
    }
}
.text__area {
    padding: 50px;
}
.btn-close {
    background: #FFAD00;
    display: block;
    color: #fff;
    width: 300px;
    margin: 0 auto;
}
    
</style>