<template>
    <div class="right-side">
        <h2> {{ $t("navigation.lot") }} </h2>
        <form class="seach__area " @submit.prevent="searchHandler">
            <div class="search__block-one">
                <input type="text" class="form-control" v-model="filter.name" :placeholder="$t('search.keyword')">
                <button type="submit" class="btn "> {{$t('search.search_btn')}} </button>
            </div>
            <div class="search__block-two">
                <div v-show="isAuth">
                    <input type="checkbox" id="includeMyTru" v-model="filter.includeMyTru">
                    <label for="includeMyTru"> {{ $t('search.include_my') }} </label>
                </div>
                <div>
                    <a class="search-hide" @click="advancedSearch = !advancedSearch"> {{ $t('search.advanced_search') }} &nbsp; 
                        <img class="arrowdown" :class="{active: advancedSearch}" src="@/assets/img/icons/arrowDown.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="search__block-hide" v-if="advancedSearch">
                <div class="form-row">
                    <div class="col-12 col-lg-8">
                        <input type="text" class="form-control" v-model="filter.exceptions" :placeholder="$t('search.exceptword')">
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="input-group">
                        <input type="text" class="form-control" v-model="filter.priceFrom" :placeholder="$t('search.pricefrom')">
                        <input type="text" class="form-control" v-model="filter.priceTo" :placeholder="$t('search.priceto')">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12">
                        <div>
                            <input type="text" class="form-control " v-model="filter.organizer" :placeholder="$t('search.organizer')">
                        </div>
                    </div>
                    <div class="col-12">
                        <div>
                            <input type="text" class="form-control" v-model="filter.customer" :placeholder="$t('search.customer')">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-lg-8">
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="filter.ensTru"
                            @input="ensTru" 
                            :placeholder="$t('announce.truId')"
                            v-on-clickaway="away"
                        >
                        <div class="tru-block" v-if="allTru">
                            <div
                                v-for="tru in allTru"
                                :key="tru.id"
                                class="tru-block__item"
                                @click="clicktruHandler(tru.code)"
                                
                            >
                                <p>
                                    {{tru.code}}
                                </p>
                                <p>
                                    {{tru.nameRu}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <v-select 
                            class="style-chooser"
                            label="name"
                            v-model="filter.tru"
                            :reduce="o => o.id"
                            :placeholder="$t('plan.tru')"
                            :options="truOptions">
                            </v-select> 
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-lg-8">
                        <v-select 
                            class="style-chooser"
                            v-if="tradeMethod"
                            :label="getFieldName('name')"
                            multiple
                            v-model="filter.tradeMethodId"
                            :reduce="prefix => prefix.id"
                            :placeholder="$t('search.method')"
                            :options="tradeMethod">
                        </v-select> 
                    </div>
                    <div class="col-12 col-lg-4">
                        <v-select 
                            class="style-chooser"
                            v-if="lotStatus"
                            :label="getFieldName('name')"
                            multiple
                            v-model="filter.lotStatusId"
                            :reduce="prefix => prefix.id"
                            :placeholder="$t('search.status')"
                            :options="lotStatus">
                        </v-select> 
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-lg-8 ">
                        <v-select 
                            class="style-chooser"
                            v-if="purchaseAreas"
                            label="name"
                            multiple
                            v-model="filter.systemId"
                            :reduce="prefix => prefix.id"
                            :placeholder="$t('search.system')"
                            :options="purchaseAreas">
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4">
                        
                        <shops-tree @setCato="setCato" :cato="filter.cato" />

                    </div>
                </div>
                
                <div class="search__block-two">
                    <div>
                        
                    </div>
                    <div>
                        <button type="reset" @click="resetHandler" class="btn btn__reset"> {{$t('search.reset')}} </button>
                        <button type="submit" class="btn btn__search"> {{ $t('search.search_btn') }} </button>
                    </div>
                </div>
            </div>
        </form>
        <div class="table-area">
            <div class="paginator-area">
                <div class="paginator">
                    <paginate
                        v-model="currentPage"
                        :page-count="pageCount"
                        :click-handler="changePage"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :page-link-class="'page-link'"
                        :prev-class="'page-item'"
                        :prev-link-class="'page-link'"
                        :next-class="'page-item'"
                        :next-link-class="'page-link'"
                        >
                    </paginate>
                </div>
                <div class="sort-area">
                    <p> {{$t('sort.sort_by')}} </p>
                    <div class="sort-area__select">
                        <v-select 
                            @input="sortHandler"
                            class="style-chooser"
                            v-if="options"
                            label="title"
                            v-model="selected"
                            :reduce="prefix => prefix.sortBy"
                            placeholder="Выберите"
                            :options="options"
                        >
                            <template slot="option" slot-scope="option">
                                {{ option.title }}
                                <span class="ml-3" :class="option.icon"></span>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger py-5 mb-5" role="alert" v-if="!isAuth && currentPage >= 3">
                <p class="text-center">{{$t("message.isNotAuth")}}</p>
            </div>
            <div v-else>
                <beat-loader 
                    v-if="loading" 
                    class="loader-center" 
                    :color="'#553DB4'" 
                    :loading="loading" 
                    :size="20" 
                    :sizeUnit="'px'"
                /> 
                <shops-table 
                    v-else 
                    :items="this.content.items"
                    @getPlans="getPlans"
                />
                <div v-if="this.content.items == 0" class="text-center not-found">
                    {{ $t("message.nothing_found") }}
                    <button type="reset" @click="resetHandler" class="btn btn__reset"> {{$t('search.reset')}} </button>
                </div>
            </div>
            <div class="paginator-area">
                <div class="paginator">
                    <paginate
                    v-model="currentPage"
                        :page-count="pageCount"
                        :click-handler="changePage"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :page-link-class="'page-link'"
                        :prev-class="'page-item'"
                        :prev-link-class="'page-link'"
                        :next-class="'page-item'"
                        :next-link-class="'page-link'"
                        >
                    </paginate>
                </div>
                <div class="quantity__text">
                    <p>{{$t('plan.row_cnt')}}: {{this.content.totalElements | sum}}</p>
                </div>
            </div>
        </div>

        <modal-box boxName="lot-info" :boxHeight="700" ref="modalLot" :lot="LotInfo"></modal-box>
    </div>
</template>

<script>

import shopsTable from '@/components/lotsPage/shopsTable'
import shopsTree from '@/components/page/shopsTree'
import ModalBox from '@/components/ui/LotModalBox'
import { mapGetters } from 'vuex'
import { mixin as clickaway } from "vue-clickaway"
import translateMix from '@/mixins/translate'


    export default {
        name: 'tender',
        mixins: [
            clickaway, 
            translateMix
        ],
        components: {
            shopsTable, shopsTree,
            ModalBox
        },
        props: {
            keywords: String
        },
        data: () => ({
            LotInfo: null,
            content: {
                totalElements: null,
                items: null
            },
            selected: null,
            loading: true,
            advancedSearch: false,
            size: 15,
            currentPage: 1,
            allTru: null,
            filter: {
                name: null,
                exceptions: null,
                priceFrom: null,
                priceTo: null,
                tradeMethodId: null,
                lotStatusId: null,
                systemId: null,
                cato: null,
                customer: null,
                organizer: null,
                tru: null,
                includeMyTru: null

            },
            dSystemId: null,
            purchaseAreas: null,
            tradeMethod: null,
            lotStatus: null

        }),
        mounted() {
            this.onShow();
        },
        methods: {
            onShow() {
                if (this.$route.query.keywords) {
                    this.filter.name = this.$route.query.keywords;
                }

                this.getPlans();
                this.getPurchaseArea()
                this.getTradeMethod()
                this.getStatus()
                this.getCities()
                this.$store.commit('RESET_CLICKED_CITY')

                if (this.$route.params.id) {
                    this.showLot(this.$route.params.id);
                }
            },
            showLot(id) {
                let params = {
                    entity: "Lot",
                    uuid: id
                };

                this.$store.dispatch('CLICKED_LIST', params)
                    .then(res => {
                        this.LotInfo = res.data
                        this.$refs.modalLot.show();
                    })
                    .catch(() => {
                        this.LotInfo = null
                        this.$toast.error(this.$t("message.nothing_found"));
                    })
                    ;
            },
            setCato(data) {
                this.filter.cato = data
            },
            getCities() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {
                        'dictionaryId.code': "cato", 
                        'parentId%isNull': 0
                    },
                    page: 0,
                    length: 1000,
                }
                this.$store.dispatch('GET_CITIES', params)
            },
            getStatus() {
                let params = {
                    entity: 'Taxonomy',
                    filter: {tableField: "lot_status"},
                    page: 0,
                    length: 500,
                }
                this.$store.dispatch('GET_STATUS', params)
                    .then( res => {
                        this.lotStatus = res.content
                    })
            },
            getTradeMethod() {
                let params = {
                    entity: 'MethodTrade',
                    filter: {},
                    page: 0,
                    length: 100,
                }
                this.$store.dispatch('GET_METHOD_TRADE', params)
                    .then( res => {
                        this.tradeMethod = res.content
                    })
            },
            getPurchaseArea() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {'dictionaryId.code': "System"},
                    page: 0,
                    length: 50,
                }
                this.$store.dispatch('GET_PURCHASE_AREA', params)
                    .then( res => {
                        this.purchaseAreas = res.content
                    })
            },
            searchHandler(){
                this.getPlans()
            },
            clicktruHandler(code) {
                this.filter.ensTru = code
                this.allTru = null
            },
            ensTru(){
                let params = {
                    entity: 'Enstru15',
                    filter: {code: this.filter.ensTru},
                    page: 0,
                    length: 50,
                }

                this.$store.dispatch('GET_TRU', params)
                    .then( res => {
                        this.allTru = res.data.content
                    })
            },
            resetHandler() {
                for (let val in this.filter) {
                    this.filter[val] = null
                }
                this.allTru = null
                this.currentPage = 1
                this.$store.commit('RESET_CLICKED_CITY')
                this.getPlans()
            },
            sortHandler(){
                this.currentPage = 1
                this.getPlans()
            },
            changePage(currentPage) {
                this.currentPage = currentPage
                this.getPlans()
            },
            getPlans() {
                this.loading = true;
                let params = {
                    page: this.currentPage - 1,
                    entity: '_Lot',
                    length: 15,
                    filter: {tru: null}
                }

                if (this.selected) {
                    params.sort = [this.selected]
                }

                if ( this.filter ) {
                    for (const key in this.filter) {
                        if(this.filter[key]) {
                            params.filter[key] = this.filter[key]
                        }
                    }
                }
                params.filter.includeMyTru = this.filter.includeMyTru ? 1 : 0;
                params.filter.isOOI = this.filter.isOOI ? 1 : 0;

                 this.$store.dispatch('GET_LIST', params) 
                    .then((res) => {
                        this.content.items = res.data.content
                        this.content.totalElements = res.data.totalElements
                        this.loading = false
                    })
            },
            away() {
                this.allTru = null
            }
        },
        computed: {
            ...mapGetters([
                'isAuth'
            ]),
            truOptions() {
                return [
                    {id:1, name: this.$t("market.goods")}, 
                    {id:2, name: this.$t("market.jobs")}, 
                    {id:3, name: this.$t("market.services")}
                    ];
            },
            options() {
                return [
                    {
                        title: this.$t("sort.by_name"),
                        icon: 'fas fa-sort-alpha-down',
                        sortBy: {
                        field: "name_ru", 
                        by: "asc"
                        },
                    },
                    {
                        title: this.$t("sort.by_name"),
                        icon: 'fas fa-sort-alpha-up',
                        sortBy: {
                            field: 'name_ru',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t("sort.by_area"),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'system_id',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t("sort.by_area"),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'system_id',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t("sort.by_lot_sum"),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'amount',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t("sort.by_lot_sum"),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'amount',
                            by: 'desc',
                        },
                    },
                ];
            },
            pageCount() {
                let l = this.content.totalElements
                let s = this.size
                return Math.ceil(l/s)
            },
            length_() {
                if (this.content.items) {
                    return true
                }

                return false
            }

        }
    }
</script>

<style lang="scss" scoped>
    .block__flex1 {
        .style-chooser {
            width: 100%;
        }
    }

    .tru-block{
        border: 1px solid #ced4da;
        padding: 10px;
        width: 100%;
        background: #fff;
        height: 200px;
        overflow-y: scroll;
        border-radius: 5px;
        margin-top: 10px;
        &__item {
            padding: 10px;
            cursor: pointer;
            border: 1px solid transparent;
            display: flex;
            p {
                font-size: 14px;
                margin-bottom: 10px;
                &:first-child {
                    margin-right: 15px;
                }
            }
            &:nth-child(odd) {
                background: #F0F1F7;
            }
            &:hover {
                border: 1px solid #ccc;
            }
        }
    }
    .block__flex {
        div {
            width: 100%;
            &:first-child {
                margin-right: 10px;
            }
        }
        @media (max-width: 770px) {
            div {
                margin-bottom: 10px;
                &:first-child{
                    margin-right: 10px
                }
            }
        }
    }

    
    
</style>