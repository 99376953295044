<template>
    <div class="table__block table-responsive">

        <table class="table table-hover  table-borderless">
            <thead>
                <tr>
                    <th class="col" v-if="isAuth"></th>
                    <th scope="col" class="text-left"> {{$t('search.name')}} </th>
                    <th scope="col"> {{ $t('search.customerbin') }} </th>
                    <th scope="col"> {{$t('search.area')}} </th>
                    <th scope="col"> {{$t('search.lot_sum')}} </th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="tender in items" :key="tender.id">
                    <td  v-if="isAuth">
                        <i class="far fa-star"  @click="showFav(tender.id)" :class="{activeStar: checkActive(tender)}" ></i>
                        <div class="table-modal" v-if="open === tender.id" v-on-clickaway="away">
                            <div v-if="lists">
                                <p 
                                    v-for="list in lists" 
                                    :key="list.id"
                                    :class="{activeItem: checkItem(list, tender)}"
                                    @click="chooseFav({id: list.id, plan: tender.externalId})"
                                ><i class="far" :class="[checkItem(list, tender) ? 'fa-check-circle' : 'fa-circle']"></i> {{list.name}} </p>
                            </div>
                            <p v-if="tender.userFavourites.length > 0" @click="deleteFav(tender)">
                                <i class="fas fa-trash-alt mr-2" ></i>Очистить
                            </p>
                            <div class="add-fav">
                                <div class="add-fav__header" @click="addFavOpen">
                                    Добавить<i class="fas fa-caret-down ml-2"></i>
                                </div>
                                <div class="add-fav__content" v-if="openAd">
                                    <input type="text" class="form-control" v-model="name" placeholder="Название">
                                    <button class="btn btn-default" @click="addFav">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                    <th scope="row">
                        <a href="#" @click.prevent="showLot(tender.id)"> {{ translate(tender, 'title')}}</a>
                        <p> {{translate(tender, 'description')}} </p>
                    </th>
                    <td> {{tender.customerbin}} </td>
                    <td><a href="#" @click.prevent>{{translate(tender, 'systemName')}}</a></td>
                    <td> {{tender.amount  | sum }} </td>
                </tr>
                <shops-modal v-if="lotInfo" boxName="lotModal" :lot="lotInfo" />
            </tbody>
        </table>
    </div>
</template>

<script>

import shopsModal from '@/components/ui/LotModalBox'
import { mixin as clickaway } from "vue-clickaway"
import {mapGetters} from 'vuex';

export default {
    props: ['items',],
    name: 'shops-table',
    components: {
        shopsModal
    },
    mixins: [clickaway],
    data: () => ({
        lotInfo: null,
        open: null,
        lists: null,
        colorStar: null,
        openAd: false,
        name: null,
    }),
    mounted() {
        this.getFavourites()
    },
    methods: {
        addFav() {
            let params = {
                entity: 'Favourite',
                data: {
                    name: this.name
                }
            }

            if(this.name) {
                this.$store.dispatch('ADD_FAVOURITE', params)
                .then(() => {
                    this.getFavourites()
                })
            }
        },
        addFavOpen() {
            this.openAd = !this.openAd
        },
        deleteFav(plan) {
            if (plan.userFavourites.length > 0) {
                let params = {
                    entity: 'UserFavourite',
                    uuid: plan.userFavourites[0].id
                }
                this.$store.dispatch('DELETE_FAVOURITE', params)
                    .then(() => {
                        this.$emit('getPlans')
                    })
            }
        },
        checkActive(plan) {
            if ( plan.userFavourites.length > 0 ) {
                return true;
            }
            return false;
        },
        checkItem(item, plan) {
            if ( plan.userFavourites.length > 0 && item.id === plan.userFavourites[0].favourite.id) {
                return true
            }
            return false

        },
        showFav(id) {
            this.open = id
        },
        chooseFav(payload) {
            let params = {
                entity: "UserFavourite", 
                data: {
                    favourite: {id: payload.id}, 
                    lot: payload.plan
                }
            }

            this.$store.dispatch('CHOOSE_FAVOURITE', params)
                .then(() => {
                    this.$emit('getPlans')
                    this.getFavourites()
                })
            
        },
        getFavourites() {
            let params = {
                entity: "Favourite",
                length: 100,
                page: 0
            }

            this.$store.dispatch('GET_LIST', params)
                .then(res => {
                    this.lists = res.data.content
                    this.colorStar = this.open
                    this.away()
                })
                .catch(() => {})
        },
        showLot(id) {

            let params = {
                entity: "Lot",
                uuid: id
            }

            this.$store.dispatch('CLICKED_LIST', params)
                .then(res => {
                    this.lotInfo = res.data;
                    this.$modal.show('lotModal')
                })
                .catch(() => {
                    this.lotInfo = null;
                    this.$toast.error(this.$t("message.nothing_found"));
                })
        },
        away() {
            this.open = null
        },
        translate(i, fieldname) {
            let field, alternative;
            switch(this.$i18n.locale) {
                case 'kz':
                    field = fieldname + 'Kz';
                    alternative = fieldname + 'Ru';
                    break;
                default:
                    field = fieldname + 'Ru';
                    alternative = fieldname + 'Kz';
            }
            
            return i[field] || i[alternative];
        },
    },
    computed: {
        ...mapGetters([
            'isAuth'
        ])
    }
}
</script>

<style lang="scss" scoped>
table thead tr th {
    &:first-child {
        width: 100px !important;
    }
}
</style>